<template>
 <el-card class="v_task_store_list">
   <!-- 商家任务列表 商家任务列表 -->
    <el-form :inline="true" size="mini" :model="query" class="v-task-list-order-form">
      <!-- 搜索 -->
      <el-form-item label="任务状态：">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="待支付" value="10"></el-option>
          <el-option label="待审核" value="20"></el-option>
          <el-option label="进行中" value="30"></el-option>
          <el-option label="已完成" value="40"></el-option>
          <el-option label="已取消" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="平台类型：">
        <el-select v-model="query.platform_id" @change="getList()">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="时间：">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>                            
      

      <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
              <el-option label="店铺名称" value="shop_title"></el-option>
              <el-option label="任务编号" value="id"></el-option>
          </el-select>
          <el-row>
              <el-col :span="12">
                  <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
              </el-col>
              <el-col :span="11" :offset="1">
                  <el-button type="info" @click="resetData">重置</el-button>
                  <el-button type="primary" @click="getList()">查询</el-button>
              </el-col>
          </el-row>
      </el-form-item>
  </el-form>
    <ul class="v-task-order-list">
      <li class="v-task-order-item" v-for="(item, index) in table.rows" :key="index">
        <!-- 顶部 -->
        <div class="v-task-order-item-top g-flex-align-center">
          <div class="v-task-order-item-top-left g-flex-align-center">
            <div class="v-task-order-item-top-store g-flex-align-center">
              <i class="iconfont iconchangyonglogo25" v-show="item.platform_id === 1"></i>
              <i class="iconfont iconzhifu-jingdong" v-show="item.platform_id === 2"></i>
              <i class="iconfont iconpinduoduoa" v-show="item.platform_id === 3"></i>
              <span class="v-task-order-item-top-store-value">{{item.userShop.title}}</span>
            </div>
            <div class="v-task-order-item-top-id">
              <span class="v-task-order-item-top-id-title">任务编号:</span>
              <span class="v-task-order-item-top-id-value">{{item.id}}</span>
            </div>
            <div class="v-task-order-item-top-number">
              <span class="v-task-order-item-top-number-title">总单数:</span>
              <span class="v-task-order-item-top-number-value">{{ item.search_keyword.reduce((total, prev) => { return total + Number(prev.num) }, 0 )  }}</span>
            </div>
            <p class="v-task-order-item-top-type"  v-show="item.platform_id === 1">手机淘宝</p>
            <p class="v-task-order-item-top-type"  v-show="item.platform_id === 2">手机京东</p>
            <p class="v-task-order-item-top-type"  v-show="item.platform_id === 3">手机拼多多</p>

            <span v-show="item.status === 0" class="g-grey">已取消</span>
            <span v-show="item.status === 10" class="g-red">待支付</span>
            <span v-show="item.status === 20" class="g-yellow">待审核</span>
            <span v-show="item.status === 30" class="g-green">进行中</span>
            <span v-show="item.status === 40" class="g-purple">已完成</span>

            <div class="v-task-order-item-top-number v-task-order-item-top-total-money">
              <span class="v-task-order-item-top-number-title">总金额:</span>
              <span class="v-task-order-item-top-number-value">{{ item.total_price }}</span>
            </div>
          </div>
          <div class="v-task-order-item-top-right g-flex-align-center">
            
            <el-button size="small" type="info" class="v-task-order-item-top-right-pay" v-if="item.task_order_info.s10 != 0 && auth.taskpay"  @click="setCancelOrder(item)">取消任务</el-button>

            <el-button size="small" type="primary" class="v-task-order-item-top-right-pay" v-show="item.status === 10 && auth.taskpay"  @click="apiTaskPayHandel(item)">支付</el-button>
            <p class="v-task-order-item-top-right-see-detail" v-if="auth.tasksee" @click="setForm(item)">查看详情</p>
            <p class="v-task-order-item-top-right-again" v-if="auth.taskagain" @click="$router.push({ path: '/issuetask2/taskissueyusou', query: { id: item.id }})">再发一单</p>
            <!-- <div class="v-task-order-item-top-right-delete">
              <i class="iconfont iconshanchu"></i>
            </div> -->
          </div>

        </div>

        <div class="v-task-order-item-bottom g-flex-align-center">
          <div class="v-task-order-item-bottom-left g-flex-align-center">
            <img :src="item.img[0]" alt="">
            <div class="v-task-order-item-bottom-left-right">
              <div class="v-task-order-item-bottom-left-right-top" :title="item.title" @click="titleClick(item)">
                {{item.title}}
              </div>
              <div class="v-task-order-item-bottom-left-bttom">
                <div class="v-task-order-item-bottom-left-bttom-one">
                  <span>创建时间:</span>
                  <span>{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                </div>
                <div class="v-task-order-item-bottom-left-bttom-two">
                  <span>执行时间:</span>
                  <span class="v-task-order-item-bttom-two-time" v-show="item.time_type === 1">{{ formatDate(item.time_val, 'yyyy-MM-dd hh:mm:ss')  }}</span>
                  <span class="v-task-order-item-bttom-two-time" v-show="item.time_type === 2">{{ item.start_date+ ' '+ item.startTime +':00'}} ~ {{ item.start_date+ ' '+ item.endTime +':00' }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="v-task-order-item-bottom-right g-flex-align-center">
            <!-- s0=已取消 s10=待接单  s20=待审核  s30=操作中 s40=待返款 s50=待收货 s60=待完成 s70已完成 -->
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '10')">
              <span> {{ item.task_order_info.s10 }}</span>
              <p class="g-black">待接单</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '20')">
              <span>{{ item.task_order_info.s20 }}</span>
              <p class="g-black">待审核</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '30')">
              <span> {{ item.task_order_info.s30 }}</span>
              <p class="g-black">操作中</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '40')">
              <span>{{ item.task_order_info.s40 }}</span>
              <p class="g-black">待返款</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '50')">
              <span>{{ item.task_order_info.s50 }}</span>
              <p class="g-black">待收货</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '60')">
              <span>{{ item.task_order_info.s60 }}</span>
              <p class="g-black">待完成</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '70')">
              <span>{{ item.task_order_info.s70}}</span>
              <p class="g-black">已完成</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" @click="gotoTaskStoreOrderList(item.id, '0')">
              <span>{{ item.task_order_info.s0 }}</span>
              <p class="g-black">已取消</p>
            </div>
          </div>
        </div>
      </li>
    </ul>


    <!--新增和编辑-->
    <el-dialog :close-on-press-escape="false" :title="'查看详情'" class="s-task-dialog" top="80px" width="1100px" :close-on-click-modal="false" :visible.sync="form">
                <el-form :model="formData" label-width="140px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 选择类型 -->
                    <el-tab-pane label="选择类型" name="1">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="商城类型:">
                              <span>{{formData.platform.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺名称:">
                              <span>{{formData.userShop.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺账号:">
                              <span>{{formData.userShop.account}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.platformPlay.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" v-show="formData.entrance">
                            <el-form-item label="活动单类型:">
                              <span>{{formData.entrance}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                    </el-tab-pane>
                    <!-- 试用活动商品信息 -->
                    <el-tab-pane label="试用活动商品信息" name="2">
                      <el-row>
                        <el-col :span="24">
                            <el-form-item label="商品链接:">
                              <span>{{formData.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="商品标题:">
                              <span>{{formData.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="详情页验证关键词:">
                              <span>{{formData.detail_keyword}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="宝贝示例图片:">
                              <el-row :gutter="20">
                                <el-col :span="3" v-for="(item,index) in formData.img" :key="index">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索排序方式:">
                              <span>{{formData.search_sort}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="筛选条件备注:">
                              <span>{{formData.search_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索展示价:">
                              <span>{{formData.search_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="拍下件数:">
                              <span>{{formData.buy_num}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="宝贝下单价:">
                              <span>{{formData.actual_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="是否包邮:">
                              <span>{{formData.is_free ? '包邮':'不包邮' }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-show="formData.entrance">
                          <el-col :span="16" v-show="formData.entrance === '淘口令'">
                            <el-form-item label="淘口令:">
                              <span>{{formData.tkl}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="16">
                            <el-form-item label="入口描述:" >
                              <span>{{formData.entrance_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- v-show="formData.entrance" -->
                        <el-row v-show="formData.entrance" >
                          <el-col :span="15">
                            <el-form-item label="步骤截图:">
                              <el-col :span="3" v-for="(item,index) in formData.entrance_pic" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="[formData.entrance_pic]"></el-image>
                                </el-col>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-for="(item, index) in formData.goods" :key="index">
                          <div class="s-hr" v-show="formData.goods.length > 1"></div>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品链接' + (index+1) + ':'">
                              <span>{{item.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品图片'+ (index+1) + ':'">
                               <el-image  style="width: 100px; height: 100px" fit="cover" :src="item.img" :preview-src-list="[item.img]"></el-image>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item :label="'附加商品价格' + (index+1) + ':'">
                              <span>{{item.price}}元</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item :label="'附加商品拍下件数' + (index+1) + ':'">
                              <span>{{item.num}}件</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 选择任务类型和单数 -->
                    <el-tab-pane label="选择任务类型和单数" name="3">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.task_type.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.tkl">
                            <el-form-item label="淘口令:">
                              <span>{{ formData.tkl }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                         <el-row>
                          <el-col :span="24" v-if="formData.entrance_store">
                            <el-form-item label="进店链接:">
                              <span>{{ formData.entrance_store }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- 普通好评 -->
                        <div v-if="formData.task_type.id == 10">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 文字好评 -->
                        <div v-if="formData.task_type.id == 11">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'指定文字任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 图片好评 -->
                        <div v-if="formData.task_type.id == 12">
                          <el-row :gutter="30" v-for="(item,index) in formData.search_keyword" class="g-padtop20 g-padbtom20" :key="index">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'图片好评任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item :label="'图片好评商品规格'+(index+1)+':'">
                                <span>{{item.size}}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'图片好评晒图'+(index+1)+':'">
                                <el-row :gutter="30">
                                  <el-col :span="4" v-for="(item2, index2) in item.img" :key="index2">
                                    <el-image style="width: 100px; height: 100px" fit="cover" :src="item2" :preview-src-list="item.img"></el-image>
                                  </el-col>
                                </el-row>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>
                    </el-tab-pane>
                    <!-- 试用活动要求 -->
                    <el-tab-pane label="试用活动要求" name="4">
                        <el-row v-show="formData.xdsj.title">
                          <el-col :span="24">
                            <el-form-item label="下单时间:">
                              <span>{{formData.xdsj.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="货比商品:">
                              <span>{{formData.huobi.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="浏览副宝贝:">
                              <span>{{formData.liulan.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="主宝贝浏览:">
                              <span>{{formData.main_liulan.map(item => item.title).join(',')}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="付款方式:">
                              <span>{{formData.pay_type}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="操作中截图:">
                              <span>{{formData.screenshot == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="确认收货截图:">
                              <span>{{formData.screenshot_arrival == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                          
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明:">
                              <span>{{formData.explain}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明示例图:">
                              <el-row :gutter="30">
                                <el-col :span="3" v-for="(item, index) in formData.explain_img" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.explain_img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="活动参与人群标签与资格" name="5">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="商家审号服务:">
                              <span>{{formData.task_pass ? '开启' : '关闭'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="开通花呗:">
                              <span>{{formData.credit_pay ? '需要': '不需要'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="信誉要求:">
                              <span>{{formData.xinyu.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="性别限制:">
                              <span>{{formData.sex === 0 ? '不限制': (formData.sex === 1 ? '男号': '女号' )}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="年龄限制:">
                              <span>{{formData.age === 0 ? '不限制': ((formData.age === 1) ? '18~25岁': ((formData.age === 2) ? '26-35岁' : '35岁以上')) }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="地区限制:">
                              <span>{{formData.region}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="店铺申请限制:">
                              <span v-show="formData.shop_limit === 0">不限制</span>
                              <span v-show="formData.shop_limit !== 0">{{formData.shop_limit}} 天内禁止申请该店铺的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布申请限制:">
                              <span v-show="formData.account_limit === 0">不限制</span>
                              <span v-show="formData.account_limit !== 0">{{formData.account_limit}}天 内禁止申请我账号下发布的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                         <el-row>
                          <el-col :span="24">
                            <el-form-item label="加赏任务佣金:">
                              <span>{{formData.reward}}元 / 单</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>

                    <el-tab-pane label="试用活动发布方式" name="6">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布方式:">
                              <span>{{formData.time_type === 1 ? '立即发布' : '小时发布'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 1">
                          <el-col :span="24">
                            <el-form-item label="任务发布时间:">
                              <span>{{ formatDate(formData.time_val, 'yyyy-MM-dd hh:mm:ss')  }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 2">
                          <el-col :span="24">
                            <el-form-item label="任务开始日期:">
                              <span>{{ formData.start_date }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.startTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.endTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务发布间隔:">
                              <span>{{Number(formData.time_interval)}} 分钟</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务回收时长:">
                              <span>{{ taskTimeList.find(item => item.value === formData.close_time).name}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                    </el-tab-pane>
                  </el-tabs>

                  <el-row>
                    <el-col :span="18">
                      <el-form-item label="审核不通过理由:">
                         <el-input placeholder="请输入审核不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="form = false">取 消</el-button>
            <el-button type="primary" @click="form = false">确定</el-button>
        </div>
    </el-dialog>

    <div class="v-task-order-list-pagination">
      <el-pagination :page-sizes="[5, 10, 15, 20]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange"  hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
 </el-card>
</template>

<script>
import { apiGetTaskList, apiGetShopTypeList, apiTaskPay, apiCancelTask } from '@/utils/api.js'
import { formatDate } from '@/utils/index.js'
export default {
  data() {
    return {
      num: 5,
      auth: {
        taskpay: this.$global.ApiList.includes(25), //任务支付
        taskagain: this.$global.ApiList.includes(43), //再发一单
        tasksee: this.$global.ApiList.includes(44), //查看任务详情
      },

      activeName:'1',
      platFormList: [ { id: 'ALL', title: '全部'} ],
      query: {
          type: 2,
          status: this.$route.query.status ? this.$route.query.status : 'ALL',
          platform_id: 'ALL',
          title:'',
          date_time: [],
          search_key: this.$route.query.id ? 'id' : 'shop_title', search_val: this.$route.query.id ? this.$route.query.id : '',
          page: 1,
          limit: 5
      },
      table: { total: 0, rows: []},
      form: false,
      title: '开心掌柜',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
      
      formData: {
        img: [],
        remark: '',
        userShop: { account : '' },
        platform: { title: '' },
        platformPlay: { title: '' },
        goods: [],
        task_type: { title: '' },
        search_keyword: [],
        entrance_pic: [],
        xdsj: { title: '' },
        huobi: { title: '' },
        liulan: { title: '' },
        main_liulan: [{ title: '' }],
        explain_img: [],
        xinyu: { title: '' },
        shop_limit: 0,
        account_limit: 0,
        close_time: 0,
        time_interval: 0,
        time_val: 0
      },
    }
  },
  async created() {
    await this.apiGetShopTypeListHandel()
    await this.getList()
  },
  methods: {
      formatDate,
      // 跳转
      gotoTaskStoreOrderList(taskId, status) {
        this.$router.push({ path: '/issuetask2/taskstoreyusouolistorder', query: { taskId, status }})
      },
      // 点击取消任务
      setCancelOrder(item) {
        this.$confirm('此操作会将该任务取消, 是否继续?', '取消任务', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.apiCancelTaskHandel(item)
          }).catch(() => {});
      },

      // 取消任务
      async apiCancelTaskHandel(item) {
        let loading = this.$loading()
        const { success, data } = await apiCancelTask({ id: item.id }).catch(() => { loading.close() })
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.getList(false)
      },
      // 点击重置
      resetData() {
        this.query = {
            type: 2,
            status: this.$route.query.status ? this.$route.query.status : 'ALL',
            platform_id: 'ALL',
            title:'',
            date_time: [],
            search_key: this.$route.query.id ? 'id' : 'shop_title', search_val: this.$route.query.id ? this.$route.query.id : '',
            page: 1,
            limit: 5
        }
        this.getList()
      },
      // 点击商品标题跳转
      titleClick({ url }) {
        window.open(url)
      },
      
      // 支付任务金额
      async apiTaskPayHandel(obj = { id: '' }) {
        let loading = this.$loading()
        const { success, data } = await apiTaskPay({ id: obj.id }).catch(() => { loading.close() })
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.getList(false)
        console.log(data)
      },

      resetDialog() {
        this.activeName = '1'
        this.form = false
      },

      //设置form
      setForm(formData = { id: '' }) {
        console.log(formData)
        this.formData = JSON.parse(JSON.stringify(formData)) 
        this.form = true
      },
      // 获取平台列表
      async apiGetShopTypeListHandel() {
        const { success, data } = await apiGetShopTypeList()
        if(!success) return
        this.platFormList = this.platFormList.concat(data.list)
      },

      //获取列表
      async getList(pageInit = true) {
          if (pageInit) this.query.page = 1
          let loading = this.$loading()
          const { success, data } = await apiGetTaskList(this.query).catch(() => { loading.close() })
          loading.close()
          if(!success) return
          this.table.rows = data.rows
          this.table.total = data.total
      },
      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      }
  }
}
</script>

<style lang="scss">
  .v_task_store_list {
    overflow-x: auto;
    // .v-task-list-order-form {
    //   // width: 1200px;
    // }
    .v-task-order-list {
      // border: 1px solid#e6e6e6;
      width: 90%;
      min-width: 1200px;
      .v-task-order-item {
        border: 1px solid#e6e6e6;
        margin-top: 30px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .v-task-order-item-top {
          padding: 10px 20px;
          font-size: 16px;
          border-bottom: 1px solid#e6e6e6;
          .v-task-order-item-top-left {
            flex: 1;
            .v-task-order-item-top-store {
              .iconfont {
                color: $mainRed;
                font-size: 26px;
              }
              .v-task-order-item-top-store-value {
                padding-left: 4px;
                color: $black;
              }
            }

            .v-task-order-item-top-id {
              padding-left: 20px;
              color: #999;
              .v-task-order-item-top-id-value {
                padding-left: 10px;
              }
            }
            .v-task-order-item-top-number {
              padding-left: 20px;
              color: $mainRed;
              .v-task-order-item-top-number-value {
                padding-left: 10px;
              }
              &.v-task-order-item-top-total-money {
                color: royalblue;
                font-weight: bold;
              }
            }
            .v-task-order-item-top-type {
              padding-left: 20px;
              padding-right: 20px;
              color: #999;
            }
          }

          .v-task-order-item-top-right {
            .v-task-order-item-top-right-see-detail {
              color: #FEB300;
              padding: 0 10px;
              cursor: pointer;
            }
            .v-task-order-item-top-right-again {
              color: $blue;
              cursor: pointer;
            }
            .v-task-order-item-top-right-pay {
              // padding: 5px 16px;
              margin: 0 10px;
            }
            .v-task-order-item-top-right-delete {
              margin-left: 20px;
              .iconfont {
                padding: 0 10px;
                font-size: 18px;
                cursor: pointer;
              }
            }
          }
        }

        .v-task-order-item-bottom {
          padding: 0 20px;
          .v-task-order-item-bottom-left {
            width: 500px;
            padding: 20px 0;
            padding-right: 20px;
            border-right: 1px dashed #e6e6e6;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 4px;
            }
            .v-task-order-item-bottom-left-right {
              padding-left: 20px;
              flex: 1;
              .v-task-order-item-bottom-left-right-top {
                width: 380px;
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                cursor: pointer;
                @include textEllipsis(2);
                &:hover {
                  color: $blue;
                  text-decoration: underline;
                }
              }
              .v-task-order-item-bottom-left-bttom {
                color: $black;
                .v-task-order-item-bottom-left-bttom-one {
                  font-size: 14px;
                  padding-top: 10px;
                  span {
                    &:nth-last-of-type(1) {
                      color: $grey;
                      padding-left: 10px;
                    }
                  }
                }
                .v-task-order-item-bottom-left-bttom-two {
                  font-size: 14px;
                  padding-top: 10px;
                  span {
                    &.v-task-order-item-bttom-two-time {
                      color: $grey;
                      padding-left: 10px;
                    }
                  }
                }
              }
            }
          }

          .v-task-order-item-bottom-right {
            flex: 1;
            align-self: stretch;
            .v-task-order-item-bottom-right-item {
              flex: 1;
              align-self: stretch;
              padding: 20px 0;
              border-right: 1px dashed #e6e6e6;
              cursor: pointer;
              &:nth-last-of-type(1) {
                border-right: none;
              }
              span {
                font-size: 18px;
                font-weight: bold;
              }
              p {
                padding-top: 14px;
                font-size: 14px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }

    .v-task-order-list-pagination {
      margin-top: 30px;
    }
    .s-task-dialog {
      .el-form {
        .el-form-item__label {
          font-weight: bold;
        }
        .el-form-item__content {
          color: $grey;
        }
      }
    }
    .s-hr {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background: #e2e3e6;
    }
  }
</style>
